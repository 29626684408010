export const MAP_TILES = {
    '-1': {
        url: 'default.png',
        type: 'vide'
    },
    0: {
        url: 'sol_plancher.png',
        type: 'sol'
    },
    1: {
        url: 'sol_plancher.png',
        type: 'sol'
    },
    2: {
        url: 'mur_haut.png',
        type: 'mur',
    },
    3: {
        url: 'mur_gauchedroite.png',
        type: 'mur',
    },
    4: {
        url: 'mur_bas.png',
        type: 'mur'
    },
    5: {
        url: 'mur_coin_hautgauche.png',
        type: 'mur',
    },
    6: {
        url: 'mur_coin_hautdroite.png',
        type: 'mur',
    },
    7: {
        url: 'mur_coin_basdroite.png',
        type: 'mur',
    },
    8: {
        url: 'mur_coin_basgauche.png',
        type: 'mur',
    },
    9: {
        url: 'mur_bord_haut.png',
        type: 'mur',
    },
    10: {
        url: 'mur_bord_droite.png',
        type: 'mur',
    },
    11: {
        url: 'mur_bord_bas.png',
        type: 'mur',
    },
    12: {
        url: 'mur_bord_gauche.png',
        type: 'mur',
    },
    13: {
        url: 'mur_bord_coin_hautgauche.png',
        type: 'mur',
    },
    14: {
        url: 'mur_bord_coin_haudroite.png',
        type: 'mur',
    },
    15: {
        url: 'mur_bord_coin_basdroite_.png',
        type: 'mur',
    },
    16: {
        url: 'mur_bord_coin_basgauche.png',
        type: 'mur',
    },
    17: {
        url: 'mur_porte_bord_hautgauche.png',
        type: 'mur',
    },
    18: {
        url: 'mur_porte_bord_hautcentre.png',
        type: 'mur',
    },
    19: {
        url: 'mur_porte_bord_hautdroite.png',
        type: 'mur',
    },
    20: {
        url: 'mur_porte_hautgauche.png',
        type: 'mur',
    },
    21: {
        url: 'mur_porte_hautdroite.png',
        type: 'mur',
    },
    22: {
        url: 'mur_porte_bord_basgauche.png',
        type: 'mur',
    },
    23: {
        url: 'mur_porte_bord_bascentre.png',
        type: 'mur',
    },
    24: {
        url: 'mur_porte_bord_basdroite.png',
        type: 'mur',
    },
    25: {
        url: 'mur_porte_basgauche.png',
        type: 'mur',
    },
    26: {
        url: 'mur_porte_basdroite.png',
        type: 'mur',
    },
    27: {
        url: 'mur_porte_bord_droitehaut.png',
        type: 'mur',
    },
    28: {
        url: 'mur_porte_bord_droitecentre.png',
        type: 'mur',
    },
    29: {
        url: 'mur_porte_bord_droitebas.png',
        type: 'mur',
    },
    30: {
        url: 'mur_porte_droitehaut.png',
        type: 'mur',
    },
    31: {
        url: 'mur_porte_droitebas.png',
        type: 'mur',
    },
    32: {
        url: 'mur_porte_bord_gauchehaut.png',
        type: 'mur',
    },
    33: {
        url: 'mur_porte_bord_gauchecentre.png',
        type: 'mur',
    },
    34: {
        url: 'mur_porte_bord_gauchebas.png',
        type: 'mur',
    },
    35: {
        url: 'mur_porte_gauchehaut.png',
        type: 'mur',
    },
    36: {
        url: 'mur_porte_gauchebas.png',
        type: 'mur',
    },
    40: {
        url: 'porte_haut.png',
        type: 'porte',
    },
    41: {
        url: 'porte_bas.png',
        type: 'porte',
    },
    42: {
        url: 'chemin_gauche.png',
        type: 'porte',
    },
    43: {
        url: 'chemin_droite.png',
        type: 'porte',
    },
    44: {
        url: 'chemin_haut.png',
        type: 'porte',
    },
    45: {
        url: 'chemin_bas.png',
        type: 'porte',
    },
    46: {
        url: 'chemin_gauche.png',
        type: 'porte',
    },
    47: {
        url: 'chemin_gauche.png',
        type: 'porte',
    },
    48: {
        url: 'porte_droite.png',
        type: 'porte',
    },
    49: {
        url: 'chemin_droite.png',
        type: 'porte',
    },
    50: {
        url: 'chemin_gauche.png',
        type: 'porte',
    },
    51: {
        url: 'bleu.png',
        type: 'porte',
    },
    52: {
        url: 'chemin_droite.png',
        type: 'porte',
    },
    53: {
        url: 'chemin_droite.png',
        type: 'porte',
    },
    54: {
        url: 'chemin_gauche.png',
        type: 'porte',
    },
    55: {
        url: 'sol_colisee.png',
        type: 'porte',
    },
    56: {
        url: 'hall_escalierhaut.png', //sous-sol escalier haut
        type: 'porte',
    },
    57: {
        url: 'hall_tapisvertical.png', //salle trone
        type: 'porte',
    },
    58: {
        url: 'hall_escalierbas.png', //sous-sol escalier bas
        type: 'porte',
    },
    59: {
        url: 'hall_tapisescalier.png', //chambre roi
        type: 'porte',
    },
    60: {
        url: 'hall_porte7.png', //pour entrée château
        type: 'porte',
    },
    61: {
        url: 'bleu.png', //pour entrée château
        type: 'porte',
    },
    62: {
        url: 'bleu.png', //pour entrée château
        type: 'porte',
    },
    67: {
        url: 'panneau_face.png',
        type: 'panneau',
    },
    68: {
        url: 'panneau_dos.png',
        type: 'panneau',
    },
    69: {
        url: 'vide_bleu.png',
        type: 'vide',
    },
    70: {
        url: 'coffre1_gauche.png',
        type: 'coffre',
    },
    71: {
        url: 'coffre1_droite.png',
        type: 'coffre',
    },
    72: {
        url: 'fleurs.png',
        type: 'objet',
    },
    73: {
        url: 'table_hautgauche.png',
        type: 'objet',
    },
    74: {
        url: 'table_hautdroite.png',
        type: 'objet',
    },
    75: {
        url: 'table_basgauche.png',
        type: 'objet',
    },
    76: {
        url: 'table_basdroite.png',
        type: 'objet',
    },
    77: {
        url: 'cle3.png',
        type: 'cle',
    },
    78: {
        url: 'glace.png',
        type: 'glace',
    },
    79: {
        url: 'glace_roche.png',
        type: 'roche',
    },
    80: {
        url: 'sol_pierre.png',
        type: 'sol',
    },
    81: {
        url: 'socle_gauche.png',
        type: 'objet',
    },
    82: {
        url: 'socle_droite.png',
        type: 'panneau',
    },
    83: {
        url: 'escalier_haut.png',
        type: 'porte',
    },
    84: {
        url: 'escalier_centre.png',
        type: 'porte',
    },
    85: {
        url: 'barriere.png',
        type: 'objet',
    },
    86: {
        url: 'herbe.png',
        type: 'objet',
    },
    87: {
        url: 'chemin.png',
        type: 'sol',
    },
    88: {
        url: 'chemin_gauche.png',
        type: 'porte',
    },
    89: {
        url: 'chemin_droite.png',
        type: 'porte',
    },
    90: {
        url: 'chemin_haut.png',
        type: 'porte',
    },
    91: {
        url: 'chemin_bas.png',
        type: 'porte',
    },
    92: {
        url: 'vide_vert.png',
        type: 'vide',
    },
    93: {
        url: 'vide_marron',
        type: 'vide',
    },
    94: {
        url: 'barriere_droite.png',
        type: 'objet',
    },
    95: {
        url: 'barriere_gauche.png',
        type: 'objet',
    },
    96: {
        url: 'panneau_face.png',
        type: 'panneau',
    },
    97: {
        url: 'panneau_dos.png',
        type: 'panneau',
    },
    98: {
        url: 'escalier_bas.png',
        type: 'porte',
    },
    99: {
        url: 'herbebarcoin2.png',
        type: 'objet',
    },
    100: {
        url: 'herbebarcoin1.png',
        type: 'objet',
    },
    101: {
        url: 'herbebarcoin4.png',
        type: 'objet',
    },
    102: {
        url: 'herbebarcoin3.png',
        type: 'objet',
    },
    //Tuile pont cassé/
    103: {
        url: 'cameleon.png',
        type: 'panneau',
    },
    104: {
        url: 'pont_casse.png',
        type: 'sol',
    },
    105: {
        url: 'pontepais.png',
        type: 'eau',
    },
    106: {
        url: 'pont_casse2.png',
        type: 'sol',
    },
    107: {
        url: 'pontepais.png',
        type: 'eau',
    },
    108: {
        url: 'vide_bleu.png',
        type: 'vide',
    },
    109: {
        url: 'eau.png',
        type: 'eau',
    },
    110: {
        url: 'eauroche.png',
        type: 'mur',
    },
    111: {
        url: 'eaubas.png',
        type: 'eau',
    },
    //Tuiles entrée château/
    112: {
        url: 'pont.png',
        type: 'sol',
    },
    113: {
        url: 'murchateau.png',
        type: 'mur',
    },
    114: {
        url: 'coinportechateau1.png', //haut/
        type: 'mur',
    },
    115: {
        url: 'coinportechateau2.png', //bas/
        type: 'mur',
    },
    116: {
        url: 'portechateauhaut.png',
        type: 'mur',
    },
    117: {
        url: 'portechateaucentre.png',
        type: 'porte',
    },
    118: {
        url: 'portechateaubas.png',
        type: 'mur',
    },
    // Tuiles colisée
    120: {
        url: 'sol_colisee.png',
        type: 'sol',
    },
    121: {
        url: 'solmur_colisee.png',
        type: 'mur',
    },
    122: {
        url: 'colonne_haut.png',
        type: 'mur',
    },
    123: {
        url: 'colonne_droite.png',
        type: 'mur',
    },
    124: {
        url: 'colonne_coin2.png',
        type: 'mur',
    },
    125: {
        url: 'colonne_coin1.png',
        type: 'mur',
    },
    126: {
        url: 'colonne_coin3.png',
        type: 'mur',
    },
    127: {
        url: 'colonne_coin4.png',
        type: 'mur',
    },
    128: {
        url: 'colonne_bas.png',
        type: 'mur',
    },
    129: {
        url: 'colonne_gauche.png',
        type: 'mur',
    },
    130: {
        url: 'entree_lumiere.png',
        type: 'mur',
    },
    131: {
        url: 'sol_colisee.png',
        type: 'miroir',
    },
    132: {
        url: 'sol_colisee.png',
        type: 'miroir',
    },
    133: {
        url: 'sol_colisee.png',
        type: 'miroir',
    },
    134: {
        url: 'sol_colisee.png',
        type: 'miroir',
    },
    135: {
        url: 'sol_colisee.png',
        type: 'miroir',
    },
    136: {
        url: 'sol_colisee.png',
        type: 'miroir',
    },
    137: {
        url: 'sol_colisee.png',
        type: 'miroir',
    },
    138: {
        url: 'sol_colisee.png',
        type: 'miroir',
    },
    139: {
        url: 'sol_colisee.png',
        type: 'miroir',
    },
    140: {
        url: 'sol_colisee.png',
        type: 'miroir',
    },
    141: {
        url: 'sol_colisee.png',
        type: 'miroir',
    },
    142: {
        url: 'sol_colisee.png',
        type: 'miroir',
    },
    143: {
        url: 'sol_colisee.png',
        type: 'miroir',
    },
    144: {
        url: 'sol_colisee.png',
        type: 'miroir',
    },
    145: {
        url: 'sol_colisee.png',
        type: 'panneau',
    },
    //Tuiles roi
    147: {
        url: 'king.png',
        type: 'panneau',
    },
    //Bibliothèque/
    185: {
        url: 'bibilio_1.png',
        type: 'mur',
    },
    186: {
        url: 'bibilio_2.png',
        type: 'mur',
    },
    187: {
        url: 'bibilio_3.png',
        type: 'mur',
    },
    188: {
        url: 'bibilio_4.png',
        type: 'mur',
    },
    189: {
        url: 'mur_porte_bord_droitebas.png',
        type: 'mur',
    },
    190: {
        url: 'mur_porte_bord_droitehaut.png',
        type: 'mur',
    },
    191: {
        url: 'mur_porte_bord_droitecentre.png',
        type: 'mur',
    },
    192: {
        url: 'mur_porte_droitebas.png',
        type: 'mur',
    },
    193: {
        url: 'mur_porte_droitehaut.png',
        type: 'mur',
    },
    194: {
        url: 'sol_biblio.png',
        type: 'sol',
    },
    195: {
        url: 'cptoir_milbas.png',
        type: 'mur',
    },
    196: {
        url: 'cptoir_bas.png',
        type: 'mur',
    },
    197: {
        url: 'cptoir_angle.png',
        type: 'mur',
    },
    198: {
        url: 'cptoir_mildroite.png',
        type: 'mur',
    },
    199: {
        url: 'cptoir_droite.png',
        type: 'mur',
    },
    200: {
        url: 'bibilio_3.png',
        type: 'panneau',
    },
    201: {
        url: 'bibilio_2.png',
        type: 'panneau',
    },
    202: {
        url: 'bibilio_1.png',
        type: 'panneau',
    },
    //Tuiles hall château
    203: {
        url: 'hall_murpanneau.png',
        type: 'panneau',
    },
    205: {
        url: 'hall_tapishoziz.png',
        type: 'sol',
    },
    206: {
        url: 'hall_tapiscoin.png',
        type: 'sol',
    },
    207: {
        url: 'hall_tapisvertical.png',
        type: 'sol',
    },
    208: {
        url: 'hall_murhautbas.png',
        type: 'mur',
    },
    209: {
        url: 'hall_murdroitebas.png',
        type: 'mur',
    },
    210: {
        url: 'hall_murbasbas.png',
        type: 'mur',
    },
    211: {
        url: 'hall_murgauchebas.png',
        type: 'mur',
    },
    212: {
        url: 'hall_murbashaut.png',
        type: 'mur',
    },
    213: {
        url: 'hall_murhauthaut.png',
        type: 'mur',
    },
    214: {
        url: 'hall_murdroitehaut.png',
        type: 'mur',
    },
    215: {
        url: 'hall_coinHDbas.png', 
        type: 'mur',
    },
    216: {
        url: 'hall_coinHDhaut.png', 
        type: 'mur',
    },
    217: {
        url: 'hall_coinBDbas.png',
        type: 'mur',
    },
    218: {
        url: 'hall_coinBDhaut.png',
        type: 'mur',
    },
    219: {
        url: 'hall_coinHGbas.png',
        type: 'mur',
    },
    220: {
        url: 'hall_coinHGhaut.png',
        type: 'mur',
    },
    221: {
        url: 'hall_coinBGbas.png',
        type: 'mur',
    },
    222: {
        url: 'hall_coinBGhaut.png',
        type: 'mur',
    },
    223: {
        url: 'hall_murgauchehaut.png',
        type: 'mur',
    },
    224: {
        url: 'hall_porte6.png',
        type: 'mur',
    },
    225: {
        url: 'hall_porte9.png',
        type: 'mur',
    },
    226: {
        url: 'hall_porte5.png',
        type: 'mur',
    },
    227: {
        url: 'hall_porte3.png',
        type: 'mur',
    },
    228: {
        url: 'hall_porte1.png',
        type: 'mur',
    },
    229: {
        url: 'hall_porte2.png',
        type: 'mur',
    },
    230: {
        url: 'hall_porte4.png',
        type: 'mur',
    },
    231: {
        url: 'hall_porte11.png',
        type: 'mur',
    },
    232: {
        url: 'hall_porte8.png',
        type: 'mur',
    },
    233: {
        url: 'hall_porte10.png',
        type: 'mur',
    },
    234: {
        url: 'hall_porte12.png',
        type: 'mur',
    },
    235: {
        url: 'hall_pilierbas.png',
        type: 'mur',
    },
    236: {
        url: 'hall_piliermilieu.png',
        type: 'mur',
    },
    237: {
        url: 'hall_pilierhaut.png',
        type: 'mur',
    },
    238: {
        url: 'hall_escaliertrone.png',
        type: 'mur',
    },
    239: {
        url: 'hall_tapisescalier.png',
        type: 'sol',
    },
    241: {
        url: 'hall_soldebris.png',
        type: 'panneau',
    },
    242: {
        url: 'hall_socle1.png',
        type: 'panneau',
    },
    243: {
        url: 'hall_socle2.png',
        type: 'panneau',
    },
    244: {
        url: 'hall_socle3.png',
        type: 'panneau',
    },
    245: {
        url: 'hall_socle4.png', 
        type: 'panneau',
    },
    246: {
        url: 'hall_socle5.png', 
        type: 'panneau',
    },
    247: {
        url: 'hall_socle6.png', 
        type: 'panneau',
    },
    248: {
        url: 'hall_socle7.png',
        type: 'panneau',
    },
    249: {
        url: 'hall_socle8.png',
        type: 'panneau',
    },
    250: {
        url: 'hall_socle9.png', 
        type: 'panneau',
    },
    //Tuiles salle du trône
    251: {
        url: 'trone_1.png', 
        type: 'mur',
    },
    252: {
        url: 'trone_2.png', 
        type: 'mur',
    },
    253: {
        url: 'pilier_rev2.png', 
        type: 'panneau',
    },
    254: {
        url: 'pilier_rev1.png', 
        type: 'panneau',
    },
    255: {
        url: 'pilier_rev3.png', 
        type: 'panneau',
    },
    256: {
        url: 'sol_or.png', 
        type: 'sol',
    },
    // Tuiles fontaine
    500: {
        url: 'herbe_enchantee.png',
        type: 'sol'
    },
    501: {
        url: 'fontaine1.png',
        type: 'fontaine'
    },
    502: {
        url: 'fontaine2.png',
        type: 'fontaine'
    },
    503: {
        url: 'fontaine3.png',
        type: 'fontaine'
    },
    504: {
        url: 'fontaine4.png',
        type: 'fontaine'
    },
    505: {
        url: 'fontaine5.png',
        type: 'fontaine'
    },
    506: {
        url: 'fontaine6.png',
        type: 'fontaine'
    },
    507: {
        url: 'fontaine7.png',
        type: 'fontaine'
    },
    508: {
        url: 'fontaine8.png',
        type: 'fontaine'
    },
    509: {
        url: 'fontaine9.png',
        type: 'fontaine'
    },
    510: {
        url: 'fontaine10.png',
        type: 'fontaine'
    },
    511: {
        url: 'fontaine11.png',
        type: 'fontaine'
    },
    512: {
        url: 'fontaine12.png',
        type: 'fontaine'
    },
    513: {
        url: 'fontaine13.png',
        type: 'fontaine'
    },
    514: {
        url: 'fontaine14.png',
        type: 'fontaine'
    },
    515: {
        url: 'fontaine15.png',
        type: 'fontaine'
    },
    516: {
        url: 'fontaine16.png',
        type: 'fontaine'
    },
    517: {
        url: 'fontaine17.png',
        type: 'fontaine'
    },
    518: {
        url: 'fontaine18.png',
        type: 'fontaine'
    },
    519: {
        url: 'fontaine19.png',
        type: 'fontaine'
    },
    520: {
        url: 'fontaine20.png',
        type: 'fontaine'
    },
    521: {
        url: 'fontaine21.png',
        type: 'fontaine'
    },
    522: {
        url: 'fontaine22.png',
        type: 'fontaine'
    },
    523: {
        url: 'fontaine23.png',
        type: 'fontaine'
    },
    524: {
        url: 'fontaine24.png',
        type: 'fontaine'
    },
    525: {
        url: 'fontaine25.png',
        type: 'fontaine'
    },
    526: {
        url: 'fee_abeille.png',
        type: 'panneau'
    },
    527: {
        url: 'fleurs_font.png',
        type: 'mur',
    },
    528: {
        url: 'chemin_font1.png',
        type: 'sol',
    },
    529: {
        url: 'chemin_font2.png',
        type: 'sol',
    },
    530: {
        url: 'fleurs_font_arbre.png',
        type: 'mur',
    },
    531: {
        url: 'herbe_enchantee_arbre.png',
        type: 'mur',
    },
    532: {
        url: 'arbres.png',
        type: 'mur',
    },
    533: {
        url: 'arbre_haut.png',
        type: 'mur',
    },
    534: {
        url: 'arbre_bas.png',
        type: 'mur',
    },
    535: {
        url: 'arbre_gauche.png',
        type: 'mur',
    },
    536: {
        url: 'arbre_droite.png',
        type: 'mur',
    },
    537: {
        url: 'arbre_droitehaut.png',
        type: 'mur',
    },
    //Tuiles temporaires
    600: {
        url: 'rouge.png',
        type: 'mur',
    },
    601: {
        url: 'vert.png',
        type: 'sol',
    },
    602: {
        url: 'bleu.png',
        type: 'mur',
    },
    //ATTENTION NE PAS SUPPRIMER CEST LA TORTUE
    603: {
        url: 'tortue.png',
        type: 'panneau',
    },
    //on peut supprimer à partir de là
    604: {
        url: 'gris.png',
        type: 'porte',
    },
    605: {
        url: 'jaune.png',
        type: 'sol',
    },
    606: {
        url: 'rouge.png',
        type: 'mur',
    },
    607: {
        url: 'violet.png',
        type: 'objet',
    },
    608: {
        url: 'violet.png',
        type: 'objet',
    },
    609: {
        url: 'blanc.png',
        type: 'vide',
    },
    610: {
        url: 'violet.png',
        type: 'objet',
    },
    611: {
        url: 'violet.png',
        type: 'sol',
    },
    612: {
        url: 'blanc.png',
        type: 'objet',
    },

    //bottes
    700: {
        url: 'bottes.png',
        type: 'botte',
    },
    //clé du château
    701: {
        url: 'cleroyale.png',
        type: 'cle',
    },
    //épée
    704: {
        url: 'epee.png',
        type: 'arme'
    }
}