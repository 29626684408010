export const MIROIR = {
    131: {
        id: 131,
        ref: 12
    },
    132: {
        id: 132,
        ref: 6
    },
    133: {
        id: 133,
        ref: 0
    },
    134: {
        id: 134,
        ref: 3
    },
    135: {
        id: 135,
        ref: 10
    },
    136: {
        id: 136,
        ref: 11
    },
    137: {
        id: 137,
        ref: 5
    },
    138: {
        id: 138,
        ref: 4
    },
    139: {
        id: 139,
        ref: 2
    },
    140: {
        id: 140,
        ref: 1
    },
    141: {
        id: 141,
        ref: 7
    },
    142: {
        id: 142,
        ref: 9
    },
    143: {
        id: 143,
        ref: 8
    },
    144: {
        id: 144,
        ref: 13
    }
}