export const INVENTORY_OBJECTS = {
    40: {
        id: 40,
        name: 'Porte',
        description: "La porte est verrouillée...",
        pickable: false,
        descVisible : true,
        visible: false,
    },
    41: {
        id: 41,
        name: 'Porte',
        description: "La porte est verrouillée...",
        pickable: false,
        descVisible : true,
        visible: false,
    },
    70: {
        id: 70,
        name: 'Coffre',
        description: 'Un coffre imposant se trouve devant vous. Voulez-vous l’ouvrir ?',
        pickable: true,
        descVisible : true,
        visible: false,
        associatedKey: 77
    },
    71: {
        id: 71,
        name: 'Coffre',
        description: 'Un coffre imposant se trouve devant vous. Voulez-vous l’ouvrir ?',
        pickable: true,
        descVisible : true,
        visible: false,
        associatedKey: 77
    },
    72: {
        id: 72,
        name: 'Fleurs',
        description: 'De magnifiques fleurs blanches. L\'une d\'elles semble vous observer.',
        pickable: true,
        descVisible : true,
        visible: true,
    },
    73: {
        id: 73,
        name: 'Table',
        description: "C'est une vieille table en bois.",
        pickable: false,
        descVisible : true,
        visible: false,
    },
    74: {
        id: 74,
        name: 'Table',
        description: "C'est une vieille table en bois.",
        pickable: false,
        descVisible : true,
        visible: false,
    },
    75: {
        id: 75,
        name: 'Table',
        description: "C'est une vieille table en bois",
        pickable: false,
        descVisible : true,
        visible: false,
    },
    76: {
        id: 76,
        name: 'Table',
        description: "C'est une vieille table en bois",
        pickable: false,
        descVisible : true,
        visible: false,
    },
    77: {
        id: 77,
        name: 'Clé moussue',
        description: "C'est une clé couverte de mousse.",
        pickable: true,
        descVisible : true,
        visible: true,
    },
    82: {
        id: 82,
        name: 'Votre épée',
        description: "Votre épée... Vraisemblablement. Aussi terrifiante qu’imposante",
        pickable: true,
        descVisible : true,
        visible: true,
    },
    200: {
        id: 200,
        name: 'Livre I',
        description: '',
        pickable: true,
        descVisible: true,
        visible: false,
    },
    201: {
        id: 201,
        name: 'Livre II',
        description: '',
        pickable: true,
        descVisible: true,
        visible: false,
    },
    202: {
        id: 202,
        name: 'Livre III',
        description: '',
        pickable: true,
        descVisible: true,
        visible: false,
    },
    700: {
        id: 700,
        name: 'Bottes d\'Hermès',
        description: 'Des bottes équipées d\'ailes',
        pickable: true,
        descVisible: true,
        visible: true,
    },
    701: {
        id: 701,
        name: "clé dorée",
        description: 'Une imposante clé en or',
        pickable: true,
        descVisible: true,
        visible: true,
    },
    702: {
        id: 702,
        name: "victoire",
        description: 'vous avez gagné, mais osef tu vois pas',
        pickable: true,
        descVisible: true,
        visible: false,
    },
    703: {
        id: 703,
        name: "Sale merde",
        description: 'vous avez perdu, ta mère a honte de toi, tu devrais te suicider, personne ne t\'aime',
        pickable: true,
        descVisible: true,
        visible: false,
    },
    704: {
        id: 704,
        name: "Votre épée",
        description: 'Votre épée... Vraisemblablement. Aussi terrifiante qu’imposante',
        pickable: true,
        descVisible: true,
        visible: true,
    }
}